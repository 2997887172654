@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Silkscreen:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Delius&family=Krona One:wght@300..700&family=Yatra+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Faculty+Glyphic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');

html, body {
    font-size: 16px;
    background: rgb(255, 238, 222);
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-card-meta-title, .ant-card-head-title {
    font-size: 1.5rem;
    font-family: 'Krona One', cursive;
    color: antiquewhite !important;
}

.ant-card-meta-description {
    font-size: 1.1rem;
}

.site-layout-content {
    min-height: 280px;
    padding-bottom: 0;
}

h1, h2, h3, .title {
    font-family: 'Krona One', cursive!important;
    color: rgb(255 162 53) !important;
}

.about h1, .about h2, .about h3, .about .title {
    color: rgb(255, 162, 53)!important;
}

.pAbout {
    font-size: 1rem;
}

/***Homepage logo animation***/

.flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
    justify-content: space-around;
}
.flex-item {
    width: 200px;
    height: 150px;
    margin-top: 0px;
    line-height: 150px;
    color: white;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
}

/*
==============================================
tossing
==============================================
*/

.tossing:hover {
    animation-name: tossing;
    -webkit-animation-name: tossing;
    animation-duration: 2.5s;
    -webkit-animation-duration: 2.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@keyframes tossing {
    0% {
        transform: rotate(-4deg);
    }
    50% {
        transform: rotate(4deg);
    }
    100% {
        transform: rotate(-4deg);
    }
}

@-webkit-keyframes tossing {
    0% {
        -webkit-transform: rotate(-4deg);
    }
    50% {
        -webkit-transform: rotate(4deg);
    }
    100% {
        -webkit-transform: rotate(-4deg);
    }
}

.ant-timeline-item-head {
    background: transparent;
}

.ant-timeline-item-tail {
    border-left: 2px solid #d7d7d7
}

.inverse-section {
    background: #422b1b;

}

.inverse-section h1, .inverse-section h2, .inverse-section h3, .inverse-section .title, .inverse-section .ant-list-item-meta-title, .inverse-section .ant-list-item-meta-description {
    color: white !important;
}

.ant-timeline-item-content {
    font-size: 1.3rem;
}

.ant-list-item-meta-title {
    font-size: 1.2rem;
}

.ant-list-item-meta-description {
    font-size: 1.15rem;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: rgb(255 162 53);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid rgb(255 162 53);
}

.ant-list-item-meta-title a{
    color: #FFA235FF;
}


/* CUSTOM */

p {
    color: #43413E;
}

h1 {
    margin: 50px auto 0 auto;
    line-height: 2;
    font-family: 'Libre Baskerville', serif;
    font-size: 3rem;
    color: rgb(255 162 53);
}

h2 {
    font-family: 'Krona One', cursive;
    color: #43413E;
    font-weight: normal;
}

h3 {
    font-family: 'Libre Baskerville', serif;
    color: rgb(255 162 53);
}

a {
    color: #51c21a;
    text-decoration: none;
}

.courses-list .ant-list-item-meta .ant-list-item-meta-title > a {
    color: #51c21a!important;
    text-decoration: none;
}

.a-home {
    color: #918877;
    text-decoration: none;
}

a:hover {
    color: #ff9e00;
}

.courses-list .ant-list-item-meta .ant-list-item-meta-title > a:hover {
    color: #ff9e00!important;
}

hr {
    border: dotted #E9DAC1 6px;
    border-bottom: 0;
    width: 4%;
}

p {
    line-height: 1.5;
}

/* Navigation Bar */

.navbar {
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.navbar-brand {
    font-family: 'Krona One', cursive;
    font-size: 1.5rem;
    font-weight: bold;
    left: 0px;
}

.nav-item {
    padding: 0 18px;
}

.nav-link {
    font-size: 1rem;
    font-weight: 300;
    font-family: 'Krona One', cursive;
}

.navbar-full {
    position: absolute;
    right: 0px;
    background-color: #9ED2C6;
}

.container-fluid {
    background-color: #9ED2C6;
}

/***navbar & home content animation***/
/*
==============================================
floating
==============================================
*/

.ant-menu-item:hover, .floating:hover {
    animation-name: floating;
    -webkit-animation-name: floating;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
}

@keyframes floating {
    0% {
        transform: translateY(0%);
    }
    50% {
        transform: translateY(8%);
    }
    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes floating {
    0% {
        -webkit-transform: translateY(0%);
    }
    50% {
        -webkit-transform: translateY(8%);
    }
    100% {
        -webkit-transform: translateY(0%);
    }
}

/****CLASS SELECTORS***/

.top-container {
    position: relative;
    min-height: 90vh;
    background-color: transparent;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.video-container {
    position: relative;
    height: 100vh; /* Full viewport height */
    width: 99vw; /* Full viewport width */
    overflow: hidden; /* Prevent scrolling */
}

.background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%; /* Cover the entire width */
    height: 100%; /* Cover the entire height */
    object-fit: cover; /* Ensures the video covers the container without distortion */
    transform: translate(-50%, -50%); /* Center the video */
    z-index: 0; /* Place video behind other content */
}

.overlay {
    background: linear-gradient(transparent, black);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Place overlay content above the video */
}

.imgHome {
    position: fixed;
    bottom: 70px;
    left: 0;
    z-index: 0;
    width: 25%;
    opacity: 0.8;
}



.centered-container2 img {
    width: 150px; /* Stessa larghezza per tutte le immagini */
    height: 150px; /* Stessa altezza per tutte le immagini */
    border-radius: 10px; /* Arrotondamento opzionale */
    flex: 1 1 calc(33.33% - 16px); /* Default width: 1/3 of the container */
    box-sizing: border-box;
}
.centered-container2 div{
    background-color: white;
    padding: 10px;
    border-radius: 16px;
    border: 2px solid #587546;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
    gap: 16px; /* Spacing between items */
}
.middle-description{
    margin-right: 70px;
    padding: 12px;
    text-align: right;
}
@media (max-width: 768px) {
    .centered-container2 img {
        width: 75px;
        height: 75px;
    }
    .middle-description{
        margin-right: 0;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .centered-container2 img {
        width: 40px;
        height: 40px;
    }
    .middle-description{
        margin-right: 0;
    }
}


.home-watermelon-panel-title {
    font-size: 2rem;
    font-weight: lighter;
    @media (max-width: 1200px) {
        display: none;
    }
}

.miao{
    display: none;
    @media (max-width: 1200px) {
        display: block;
        margin-top: 30px;
    }
}

.miao .btn{
    margin-right: 5px;
    margin-left: 5px;
}

#svg1, #svg2 {
    fill: currentColor;
    height: auto;
    transform-origin: center;
    width: 90%;
    transform: rotate(-30deg);
    margin-bottom: -140px;
    overflow: visible;
}

#svg2 {
    transform: rotate(6deg);
}



.img-home {
    z-index: 2;
    width: 100%;
    opacity: 0.9;
}

.middle-container{
    text-align: center;
    font-size: 1.2rem;
    position: relative;
    min-height: 90vh;
    background-color: #587546;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.profile {
    padding-top: 100px;
    padding-bottom: 100px;
}

.intro {
    width: 30%;
    margin: auto;
}

.skills {
    padding-top: 100px;
    padding-bottom: 100px;
}

.skill-row {
    width: 50%;
    margin: 100px auto 100px auto;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 1em;
    padding-left: 1em;
    text-align: left;
    background-color: #E9DAC1;
    border-radius: 15px;
}

.contact-me {
    padding-top: 70px;
    padding-bottom: 70px;
}

.contact-message {
    width: 30%;
    margin: 40px auto 60px;
    @media (max-width: 1200px) {
        width: 90%;
    }
}

.about {
    margin: 40px auto 60px;
}

.third-container {
    padding: 50px 0 20px;
    background-color: #fdecdc;
}

.bottom-container {
    padding: 50px 0 20px;
    background-color: #577445;
}

.pro {
    text-decoration: underline;
}

.pixel-profile-img {
    border-image: none;
    width: 12%;
}

.video-img {
    float: left;
    margin-right: 30px;
    width: 11%;
    border-radius: 50%;
}

.code-img {
    float: right;
    margin-left: 30px;
    width: 15%;
}

.subtitle {
    font-size: 1.6rem;
}

.btn {
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    border-radius: 28px;
    font-family:  'Silkscreen', cursive;
    color: #F7ECDE;
    font-size: 20px;
    background:  #587546;
    padding: 10px 20px;
    text-decoration: none;
    @media (max-width: 1200px) {
        padding: 6px 12px;
    }
}

.btn:hover {
    background: #fda254;
    text-decoration: none;
    color: #F7ECDE;
}

.footer-link {
    padding: 10px 10px 10px 10px;
    border-radius: 15px;
    margin: 10px 10px;
}

.footer-link:hover {
    background-color: #a7cdcc;
}

.lin {
    width: 24px;
    height: 24px;
}

.footer-paragraph {
    font-size: 10px;
    font-family:  'Silkscreen', cursive;
}

.footer-credit-to-me {
    font-size: 15px;
    font-family:  'Silkscreen', cursive;
    padding: 10px 0;
}

.curved-text{
    position:relative;
    display:inline-block;
    margin:0 auto;
    font-size:32px;
}
.curved-text span{
    min-width:0.5em;
    text-align:center;
    padding:30px;
    margin:0px;
}

/****ID SELECTORS***/

#title {
    height: auto;
}

/****cv page***/

.body-cv {
    background-color: #9ED2C6;
    color:#43413E;
    text-align: justify;
}

#beginning {
    padding: 7% 15%;
    position: relative;
    z-index: 1;
}

.beginning-box {
    background-color: #587546;
    color:aliceblue;
}

.feature-box {
    text-align:justify;
    padding: 2%;
    box-sizing: content-box;
}

.cv-img {
    height: 45%;
}

#heading {
    margin: 0 auto 0 auto;
    line-height: 2;
    font-family: 'Krona One', cursive;
    font-size: 2rem;
    color: #43413E;
}

#workexperience {
    padding: 7% 10%;
}

.cv-h3 {
    margin: 0 auto 0 auto;
    line-height: 2;
    font-family: 'Krona One', cursive;
    font-size: 2rem;
    color: #587546;
}

.custom-timeline .ant-timeline-item .ant-timeline-item-content {
     font-size: 20px;
    @media (max-width: 1200px) {
    font-size: 12px
}
}

#skills {
    padding: 7% 10%;
    background-color: #1c2830;
    color:aliceblue;
    text-align: justify;
}

.carousel-item {
    padding: 7% 15%;
}

#education {
    padding: 7% 10%;
}

.cv-h4 {
    margin: 2% auto 0 auto;
    line-height: 3;
    font-family: 'Krona One', cursive;
    font-size: 1rem;
    color: #803e73;
}

#hobbies {
    background-color: #1c2830;
    color:aliceblue;
    padding: 7% 10%;
}

.cv-p {
    margin: 2% auto 0 auto;
    line-height: 3;
    font-family: 'Krona One', cursive;
    font-size: 1rem;
    color:aliceblue;
}

.beg-p {
    color:aliceblue;
}


iframe {
    overflow: hidden;
    border: none;
}

.animated-image{
    transition: all 0.4s ease-in-out;
    animation: float 6s ease-in-out infinite;
    margin-top: -15%;
    @media (max-width: 1200px) {
        margin-top: -51%;
    }
}

@keyframes float {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

/* CAT CAT CAT */
.cat-container {
    height: 200px;
    width: 350px;
    position: absolute;
    bottom: -59px;
    right: 20%
}

/* Style for the cat */
.cat {
    background-color: #f19b1a;
    height: 65px;
    width: 80px;
    border-radius: 0 80px 80px 0;
    position: absolute;
    bottom: 60px;
    right: 50px;
}

/* Style for the cat's ear */
.ear {
    height: 15px;
    width: 15px;
    background-color: #f19b1a;
    position: absolute;
    bottom: 64px;
    left: 8px;
    border-radius: 20px 0 0 0;
    box-shadow: 25px 0 #f19b1a;
}

/* Styles for the cat's eye */
.eye,
.eye:before {
    height: 7px;
    width: 10px;
    border: 2px solid #2c2c2c;
    position: absolute;
    border-radius: 0 0 15px 15px;
    border-top: none;
}

.eye {
    top: 18px;
    left: 15px;
}

.eye:before {
    content: "";
    left: 30px;
}

/* Style for the cat's nose */
.nose {
    background-color: #ffffff;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    position: absolute;
    top: 32px;
    left: 25px;
    box-shadow: 12px 0 #ffffff;
    /* Create a shadow effect for the nose */
}

/* Styles for the cat's nose elements */
.nose:before {
    content: "";
    width: 12px;
    height: 8px;
    position: absolute;
    background-color: #ffffff;
    left: 6px;
}

.nose:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border-top: 8px solid #ef926b;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    bottom: 7px;
    left: 6px;
}

/* Style for the cat's mouth */
.mouth {
    background-color: #2c2c2c;
    height: 15px;
    width: 17px;
    position: absolute;
    border-radius: 0 0 5px 5px;
    top: 38px;
    left: 27px;
    animation: mouth-move 2s infinite;
    transform-origin: top;
}

/* Style for the cat's body */
.cat-container .body {
    background-color: #f19b1a;
    height: 90px;
    width: 140px;
    position: absolute;
    right: 65px;
    bottom: 0;
    border-radius: 60px 60px 0 0;
    animation: sleep 2s infinite;
    transform-origin: bottom right;
}

/* Style for the cat's tail */
.tail {
    background-color: #d07219;
    height: 20px;
    width: 100px;
    position: absolute;
    right: 150px;
    bottom: 0;
    border-radius: 20px 0 0 20px;
}

/* Create a shadow effect for the cat's body */
.cat-container .body:before {
    content: "";
    position: absolute;
    background-color: #ffffff;
    height: 12px;
    width: 30px;
    border-radius: 6px;
    bottom: 0;
    left: 22px;
    box-shadow: 45px 0 #ffffff;
    /* Create a shadow effect for the cat's body */
}

/* Style for the cat's speech bubble */
.bubble {
    height: 20px;
    width: 20px;
    background-color: rgba(255, 255, 255, 0.4);
    position: absolute;
    left: 65px;
    top: 20px;
    border-radius: 50px 50px 50px 5px;
    animation: bubble-scale 2s infinite;
    /* Apply animation to make the bubble scale */
}

/* Style for the cat's shadow */
.cat-container .shadow {
    height: 10px;
    width: 240px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    position: absolute;
    bottom: 52px;
    left: 70px;
    animation: shadow 2s infinite;
    /* Apply animation to make the shadow scale */
}

/* Keyframes for mouth movement */
@keyframes mouth-move {
    50% {
        transform: scaleY(0.7);
    }
}

/* Keyframes for body sleeping animation */
@keyframes sleep {
    50% {
        transform: scale(0.9, 1.05);
    }
}

/* Keyframes for bubble scaling animation */
@keyframes bubble-scale {
    50% {
        transform: scale(1.6);
    }
}

/* Keyframes for shadow scaling animation */
@keyframes shadow {
    50% {
        transform: scaleX(0.7);
    }
}
.hireMeNow{
    position: absolute;
    left: -290px;
}

.page-about{
    background-color: #ffeede!important;
}
.page-about .ant-menu-item-selected {
    color: #f19b1a!important;
}
.page-about .ant-menu-item-selected:after{
    border-bottom-color: #f19b1a!important;
}